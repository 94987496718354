import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Theme, Typography, Button, Box } from '@material-ui/core'
import { useIntl, FormattedMessage } from 'react-intl'
import { Link } from '@src/components/Link'
import ResponsiveModal from '@src/components/ResponsiveModal'
import { useRouter, loginRoute } from '@src/routes'
import { useUserSession } from '@utils/useUserSession'
import messages from '@utils/messages'
import { EventKeys } from '@utils/analytics/enums'
import { sessionStorage } from 'react-storage'
import { useUpdateTermConditionMutation } from '@src/mutations/UpdateTermConditionMutation.generated'
import {
  useGlobalSnackbar,
  SnackbarType,
} from '@src/components/GlobalSnackbar/globalSnackBarContext'

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    padding: '25px 32px 32px 31px',
    lineHeight: 1.5,
  },
  bodyContent: {
    paddingRight: 40,
  },
  description: {
    marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  column: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },
  title: {
    textAlign: 'left',
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 40,
  },
  agree: {
    marginLeft: 16,
  },
}))

interface termValues {
  termUrl: string
  termKey: string
  agree: boolean
}
export interface TCUpdateProps {
  open: boolean
  setTcUpdateOpen: Function
  terms: termValues[]
  setAgreeClicked: Function
}

const TCUpdateLink = ({ term }) => {
  const intl = useIntl()
  return (
    <div>
      <Link href={term.termUrl}>
        <a target="_blank" rel="noreferrer">
          {intl.formatMessage(messages[term.termKey])}
        </a>
      </Link>
    </div>
  )
}

const TCUpdateDialog: React.FC<TCUpdateProps> = ({
  open,
  setTcUpdateOpen,
  terms,
  setAgreeClicked,
}) => {
  const classes = useStyles()
  const router = useRouter()
  const { userSessionActions } = useUserSession()
  const [sendUpdateTermCondition] = useUpdateTermConditionMutation()
  const { setGlobalSnackbarState } = useGlobalSnackbar()

  const handleLogout = async () => {
    sessionStorage.setItem<boolean>(EventKeys.IS_WECHAT_LOGGED_IN, false)

    setAgreeClicked(null)
    await userSessionActions.logout(router)
    setTcUpdateOpen(false)
    // Using `router.replace` here causes an issue where users are always redirected to the login page on logout
  }
  return (
    <ResponsiveModal
      hideCloseButton
      disableEscapeKeyDown
      renderTitle={() => (
        <Typography variant="h2" component="h2" className={classes.title}>
          <FormattedMessage {...messages.UPDATE_TERMS_CONDITIONS} />
        </Typography>
      )}
      open={open}
      onClose={() => setTcUpdateOpen(true)}
    >
      <div className={classes.body}>
        <div className={classes.bodyContent}>
          <Box marginBottom={4}>
            <FormattedMessage
              {...messages.UPDATE_TERMS_CONDITIONS_DESCRIPTION_TARIFF_POLICY_CONFIRMATION}
            />
          </Box>
          <Box marginBottom={4}>
            <FormattedMessage
              {...messages.UPDATE_TERMS_CONDITIONS_DESCRIPTION_TARIFF_POLICY}
            />
          </Box>
          {terms &&
            terms.length > 0 &&
            terms.map((term) => {
              return <TCUpdateLink key={term.termKey} term={term} />
            })}
        </div>
        <div className={classes.action}>
          <Button variant="outlined" color="primary" onClick={handleLogout}>
            {/* disagree translation */}
            <FormattedMessage {...messages.DISAGREE} />
          </Button>
          <Button
            className={classes.agree}
            variant="contained"
            color="primary"
            onClick={async () => {
              const termsReq = terms.map((term) => term.termKey)
              try {
                await sendUpdateTermCondition({
                  variables: { terms: termsReq },
                })
                await setTcUpdateOpen(false)
                setAgreeClicked(true)
              } catch (err) {
                setGlobalSnackbarState({
                  open: true,
                  message: (
                    <FormattedMessage
                      {...messages.USER_NOT_AUTHORIZED_OR_TOKEN_EXPIRED}
                    />
                  ),
                  variant: SnackbarType.Error,
                })
                if (router.pathname.includes('/login')) {
                  window.location.reload()
                } else {
                  router.push(loginRoute.index())
                }
              }
            }}
          >
            <FormattedMessage {...messages.AGREE} />
          </Button>
        </div>
      </div>
    </ResponsiveModal>
  )
}

export default TCUpdateDialog
